<template>
  <div class="online_order">
    <div class="order_status_nav">
      <div
        :class="[currentOrderType == 0 ? 'active' : '', 'nohandle']"
        @click="changeOrderType(0)"
      >
        <span v-if="orderNums.no_visit"></span>
        未处理（{{ orderNums.no_visit }}）
      </div>
      <div
        :class="[currentOrderType == 1 ? 'active' : '']"
        @click="changeOrderType(1)"
      >
        已处理（{{ orderNums.is_visit }}）
      </div>
      <div
        :class="[currentOrderType == 2 ? 'active' : '']"
        @click="changeOrderType(2)"
      >
        全部（{{ orderNums.total }}）
      </div>
    </div>
    <div class="search_wrap">
      <div>
        <el-input
          placeholder="请输入内容"
          v-model="search_keyword"
          class="input-with-select"
        >
          <el-select v-model="search_type" slot="prepend" placeholder="请选择">
            <el-option label="手机号" value="phone"></el-option>
            <el-option label="订单编号" value="order_code"></el-option>
          </el-select>
          <el-button @click="searchBtn" slot="append">搜索</el-button>
        </el-input>
      </div>
    </div>
    <div class="manage_order_list_warp">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        :header-cell-style="{
          'background-color': '#eee',
          color: '#333',
          fontSize: '16px',
        }"
      >
        <el-table-column
          v-for="(item, index) in currentColums"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        >
          <template slot-scope="scope">
            <span v-if="item.type == 'text'">{{
              scope.row[item.prop] ? scope.row[item.prop] : '-'
            }}</span>
            <div
              v-else-if="
                item.type == 'temp' &&
                (item.prop == 'operation' || item.prop == 'revisit_info')
              "
            >
              <span
                v-if="!scope.row.is_revisit"
                style="
                  color: #1890ff;
                  cursor: pointer;
                  display: inline-block;
                  margin-right: 10px;
                "
                @click="handleOrder(scope.row, true)"
                >查看详情</span
              >
              <span
                style="color: #1890ff; cursor: pointer"
                @click="handleOrder(scope.row)"
                >{{ !scope.row.is_revisit ? '处理回访' : '查看详情' }}</span
              >
            </div>

            <span
              :style="{ color: scope.row.urge_times == 0 ? 'gray' : 'red' }"
              v-else-if="item.type == 'temp' && item.prop == 'urge_times'"
              >{{ scope.row.urge_times == 0 ? '未催促' : '催促中' }}</span
            >
            <div
              style="display: flex; align-items: center"
              v-else-if="
                (item.type == 'temp' && item.prop == 'is_revisit') ||
                item.prop == 'urge_times'
              "
            >
              <span
                :style="
                  orderStatusStyle(scope.row[item.prop], scope.row.urge_times)
                "
              ></span>
              <!--  :style="{ color: scope.row[item.prop] ? '#00a854' : '#bfbfbf' }" -->
              <span
                :style="{
                  color:
                    scope.row[item.prop] == 1
                      ? '#00a854'
                      : scope.row.urge_times > 0
                      ? 'red'
                      : 'gray',
                }"
                >{{
                  scope.row[item.prop] == 1
                    ? '已处理'
                    : scope.row.urge_times > 0
                    ? '催促中'
                    : '未处理'
                }}</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="查看详情"
      :visible.sync="orderDetalDialogShow"
      width="50%"
    >
      <div class="orderDetalDialog_wrap">
        <div class="line-one marginBottom10">
          <p class="marginright">
            <span>订单编号：</span>{{ currentOrderInfo.order_code }}
          </p>
          <p class="marginright">
            <span>回访时间：</span>{{ currentOrderInfo.revisit_time }}
          </p>
          <p><span>回访者：</span>{{ currentOrderInfo.revisit_name }}</p>
        </div>
        <div class="line-two marginBottom10">
          <span class="order_info_title">订单详情：</span>
          <div class="order_info">
            <p><span>交易单号：</span>-</p>
            <p><span>商户单号：</span>-</p>
            <p>
              <span>订单金额：</span
              ><span style="color: #ed7b2f">{{ currentOrderInfo.price }}</span
              >元/张
            </p>
            <p><span>下单时间：</span>{{ currentOrderInfo.create_time }}</p>
          </div>
        </div>
        <div class="order_img">
          <div
            class="order_img_item"
            v-for="(item, i) in handleCommoditys(false)"
            :key="i"
          >
            <img :src="item.image" alt="" />
            <div>
              <span
                >{{ item.name
                }}{{
                  handleCommoditys(true) && item.goods_type == 4
                    ? `-${handleCommoditys(true).name}`
                    : ''
                }}</span
              >
              <span
                >{{ item.price
                }}<span class="order_img_price">元/张</span></span
              >
            </div>
          </div>
        </div>
        <div class="order_handle_info">
          <span>回访详情：</span>{{ currentOrderInfo.revisit_info }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="orderDetalDialogShow = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="close"
      title="处理回访"
      :visible.sync="handleDialogShow"
      width="30%"
    >
      <div class="handleDialog_wrap">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="回访者" prop="revisit_name">
            <el-input v-model="ruleForm.revisit_name"></el-input>
          </el-form-item>
          <el-form-item label="选择回访时间" prop="revisit_time" required>
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              v-model="ruleForm.revisit_time"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="回访详情" prop="revisit_info">
            <el-input
              type="textarea"
              v-model="ruleForm.revisit_info"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.page"
        :page-sizes="[10, 12, 15, 20]"
        :page-size="form.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total_count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      close: false,
      ruleForm: {
        revisit_name: '',
        revisit_time: '',
        revisit_info: '',
      },
      rules: {
        revisit_name: [
          { required: true, message: '请输入回访者', trigger: 'blur' },
        ],
        revisit_time: [
          {
            type: 'string',
            required: true,
            message: '请选择日期',
            trigger: 'blur',
          },
        ],
        revisit_info: [
          { required: true, message: '请输入回访详情', trigger: 'blur' },
        ],
      },
      currentOrderType: 0,
      search_type: 'phone',
      search_keyword: '',
      tableData: [],
      handleDialogShow: false,
      orderDetalDialogShow: false,
      currentOrderInfo: {},
      imageInfo: [],
      nohandleColumns: [
        { prop: 'order_code', label: '订单编号', type: 'text', width: '200' },
        { prop: 'name', label: '姓名', type: 'text', width: '150' },
        { prop: 'phone', label: '联系方式', type: 'text', width: '200' },
        { prop: 'create_time', label: '提交时间', type: 'text', width: '200' },
        { prop: 'area', label: '地址', type: 'text', width: 'auto' },
        { prop: 'urge_times', label: '用户催促', type: 'temp', width: 'auto' },
        { prop: 'operation', label: '操作', type: 'temp', width: '150' },
      ],
      handleColumnsed: [
        { prop: 'name', label: '姓名', type: 'text', width: '150' },
        { prop: 'phone', label: '联系方式', type: 'text', width: '200' },
        { prop: 'revisit_name', label: '回访者', type: 'text', width: '100' },
        { prop: 'create_time', label: '提交时间', type: 'text', width: 'auto' },
        {
          prop: 'revisit_time',
          label: '处理时间',
          type: 'text',
          width: 'auto',
        },
        {
          prop: 'revisit_info',
          label: '回访情况',
          type: 'temp',
          width: '150',
        },
      ],
      columns: [
        { prop: 'name', label: '姓名', type: 'text', width: '150' },
        { prop: 'phone', label: '联系方式', type: 'text', width: '200' },
        { prop: 'revisit_name', label: '回访者', type: 'text', width: '150' },
        {
          prop: 'revisit_time',
          label: '处理时间',
          type: 'text',
          width: 'auto',
        },
        { prop: 'create_time', label: '提交时间', type: 'text', width: 'auto' },
        { prop: 'is_revisit', label: '信息状态', type: 'temp', width: '200' },
        { prop: 'operation', label: '操作', type: 'temp', width: '150' },
      ],
      form: {
        page: 1,
        limit: 12,
      },
      total_count: 0,
    }
  },
  computed: {
    handleCommoditys() {
      return (needMd) => {
        let req = []
        if (needMd) {
          let mdArr = this.imageInfo.filter((item) => {
            return item.goods_type == 5
          })
          if (mdArr.length) {
            req = mdArr[0]
          } else {
            req = null
          }
        } else {
          req = this.imageInfo.filter((item) => {
            return item.goods_type != 5
          })
        }
        return req
      }
    },
    orderNums() {
      return this.$store.state.tatal_order_num.online || {}
    },
    orderStatusStyle() {
      return (status, times) => {
        return {
          width: '8px',
          height: '8px',
          'border-radius': '50%',
          backgroundColor: status == 1 ? '#00a854' : times > 0 ? 'red' : 'gray',
          display: 'inline-block',
          marginRight: '5px',
        }
      }
    },
    currentColums() {
      let cureent = []
      switch (this.currentOrderType) {
        case 0:
          cureent = this.nohandleColumns
          break
        case 1:
          cureent = this.handleColumnsed
          break
        case 2:
          cureent = this.columns
          break
      }
      return cureent
    },
  },
  methods: {
    // 搜索按钮
    searchBtn() {
      let payload = {}
      switch (this.search_type) {
        case 'phone':
          payload = {
            phone: this.search_keyword,
            limit: 12,
            page: this.form.page,
          }
          break
        case 'order_code':
          payload = {
            order_code: this.search_keyword,
            limit: 12,
            page: this.form.page,
          }
          break
      }
      if (this.currentOrderType == 0) {
        payload.is_revisit = 0
      } else if (this.currentOrderType == 1) {
        payload.is_revisit = 1
      }
      console.log('search_type', this.search_type)
      this.$request.getOrderRevisitList(payload, (res) => {
        if (res.status == 1) {
          console.log(res.data.data)
          this.tableData = res.data.data
          // 总数
          this.total_count = res.data.total
        }
      })
    },
    handleSizeChange(value) {
      this.form.limit = value
      this.getList()
    },
    // 回访取消
    cancle(name) {
      this.handleDialogShow = false
      this.$refs[name].resetFields()
    },
    // 回访确定
    submitForm(name) {
      let payload = {
        id: this.currentOrderInfo.id,
        ...this.ruleForm,
      }
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$request.toVisit(payload, (res) => {
            if (res.status == 1) {
              this.getList(true)
            }
          })
          this.getList()
          this.handleDialogShow = false
          this.$refs[name].resetFields()
          this.ruleForm = {
            revisit_name: '',
            revisit_time: '',
            handle_detail: '',
          }
          console.log('this.ruleForm', this.ruleForm)
        } else {
          return false
        }
      })
    },
    changeOrderType(value) {
      this.search_keyword = ''
      this.form.page = 1
      this.currentOrderType = value
      this.getList(true)
    },
    handleOrder(row, showOrderDeta = false) {
      this.currentOrderInfo.id = row.id
      // this.currentOrderInfo = row
      if (showOrderDeta || row.is_revisit) {
        // 查看详情
        this.$request.getRevisitDetail({ id: row.id }, (res) => {
          console.log('详情', res.data.commodity)
          this.currentOrderInfo = res.data.revisit
          this.imageInfo = res.data.commodity
        })
        this.orderDetalDialogShow = true
      } else {
        // 处理回访
        this.handleDialogShow = true
      }
    },
    handleCurrentChange(page) {
      this.form.page = page
      if (this.search_keyword) {
        this.searchBtn()
      } else {
        this.getList()
      }
    },
    getList(update = false) {
      let form = this.form
      if (this.currentOrderType < 2) {
        form.is_revisit = this.currentOrderType
        this.$request.getOrderRevisitList(form, (res) => {
          if (res.status == 1) {
            console.log(res.data.data)
            this.tableData = res.data.data

            // 总数
            this.total_count = res.data.total
            if (update) {
              // 订单状态改变时更新左侧和顶部导航订单数量
              this.$store.dispatch('getOrderNum')
            }
          }
        })
      } else {
        this.$request.getOrderRevisitList(
          { limit: this.form.limit, page: this.form.page },
          (res) => {
            if (res.status == 1) {
              console.log(res.data.data)
              this.tableData = res.data.data
              // 总数
              this.total_count = res.data.total
              if (update) {
                // 订单状态改变时更新左侧和顶部导航订单数量
                this.$store.dispatch('getOrderNum')
              }
            }
          }
        )
      }
    },
  },
  mounted() {
    this.getList()
  },
}
</script>

<style lang="less" scoped>
.online_order {
  padding: 12px;
  .order_status_nav {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    > div {
      position: relative;
      bottom: -1px;
      padding: 12px 24px;
      cursor: pointer;
      user-select: none;
      &.active {
        border-bottom: 2px solid #1890ff;
        color: #1890ff;
      }
    }
    .nohandle {
      position: relative;
      > span {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #f5222d;
        position: absolute;
        display: inline-block;
        top: 8px;
        left: 50%;
      }
    }
  }
  .search_wrap {
    display: flex;
    justify-content: flex-end;
    padding: 12px 0;
    /deep/ .el-select .el-input {
      width: 130px;
    }
    /deep/ .input-with-select .el-input-group__prepend {
      background-color: #fff;
    }
    /deep/ .el-input-group__append {
      background-color: #1890ff;
      color: #fff;
      cursor: pointer;
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
  }
  .orderDetalDialog_wrap {
    .marginright {
      margin-right: 40px;
    }
    .marginBottom10 {
      margin-bottom: 10px;
    }
    .line-one {
      display: flex;
      align-items: center;
    }
    .line-two {
      display: flex;
      .order_info_title {
        width: 75px;
      }
      .order_info {
        display: flex;
        flex-flow: wrap;
        p {
          margin-bottom: 6px;
          width: 51%;
        }
      }
      > span {
        display: inline-block;
      }
    }
    .order_img {
      margin-left: 75px;
      margin-right: 10px;
      display: flex;
      align-items: flex-start;
      margin-top: 10px;
      .order_img_item {
        margin-right: 20px;
        display: flex;
        align-items: center;
      }
      div {
        margin-top: 10px;
        display: flex;
        text-align: center;
        flex-direction: column;
        span:nth-child(2) {
          color: rgb(237, 123, 47);
          .order_img_price {
            color: black;
          }
        }
      }
      img {
        width: 60px;
        height: 60px;
        margin-right: 10px;
      }
    }
    .order_handle_info {
      margin-top: 20px;
    }
  }
  .handleDialog_wrap {
  }
}
</style>
